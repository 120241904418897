import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { toastrHelper } from '../../logic/toastrHelper';
import { QTypography } from '../../components/Q-Components/QTypography';
import { setPageTitle } from '../../store/slices/emmAppSlice';
import { QContainer } from '../../components/Q-Components/QContainer';
import { aggregateResultsForSingleRule } from '../../logic/dopplerUtil';
import { GuardianReportHeaderAndMetadata } from '../../components/Reports/GuardianReport/HeaderAndMetadata';
import { OverviewTab } from '../../components/Reports/GuardianReport/OverviewTab';
import { QTabContext } from '../../components/Q-Components/QTabContext';
import { QTabList } from '../../components/Q-Components/QTabList';
import { QTab } from '../../components/Q-Components/QTab';
import { QTabPanel } from '../../components/Q-Components/QTabPanel';
import { BehaviorsTab } from '../../components/Reports/GuardianReport/BehaviorsTab';
import { MaliciousTab } from '../../components/Reports/GuardianReport/MaliciousTab';

export function NewReport() {
  const { newQuokkaReport, mastV2 } = useFlags();

  const dispatch = useDispatch();

  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const [aggregateData, setAggregateData] = useState({});
  const [ruleSummaries, setRuleSummaries] = useState([]);
  const [behavioralRuleSummaries, setBehavioralRuleSummaries] = useState([]);
  const [threatScore, setThreatScore] = useState();
  const { farmAppUuid, analyzedAppId } = useParams();
  const [app, setApp] = useState();
  const [metadata, setMetadata] = useState({});
  const [activeTab, setActiveTab] = useState('overview');
  const [maliciousResult, setMaliciousResult] = useState({});

  const sarifBaseURL = '/analysis-results';

  // TODO: Remove this check once data is loaded into database
  // https://quokka-io.atlassian.net/browse/MAST-3841
  const checkDataLoaded = async () => {
    if (isDataLoaded) return;
    try {
      await axios.get(`${sarifBaseURL}/is-data-loaded?uuid=${farmAppUuid}`);

      setIsDataLoaded(true);
    } catch (err) {
      toastrHelper.showErrorToast(
        'Error checking if sarif data is loaded',
        'Error',
        mastV2,
      );
    }
  };

  const getApp = async () => {
    try {
      if (analyzedAppId) {
        const { data } = await axios.get(`/emm/app/${analyzedAppId}`);
        setApp(data);
      }
    } catch (err) {
      toastrHelper.showErrorToast(
        'Error loading analyzed app data',
        'Error',
        mastV2,
      );
    }
  };

  const fetchAggregateData = async () => {
    try {
      const { data } = await axios.get(
        `${sarifBaseURL}/aggregate-data?uuid=${farmAppUuid}`,
      );
      setAggregateData(data);
    } catch (err) {
      toastrHelper.showErrorToast(
        'Error loading summary data',
        'Error',
        mastV2,
      );
    }
  };

  const fetchRuleSummaries = async () => {
    try {
      const { data } = await axios.get(
        `${sarifBaseURL}/rule-summaries?uuid=${farmAppUuid}`,
      );
      data.sort((a, b) => b.result_level_numeric - a.result_level_numeric);

      const fullRuleSummaries = data.map(ruleSummary => {
        const details = aggregateResultsForSingleRule(
          ruleSummary.rule,
          {},
          ruleSummary.result_level_numeric,
        );
        return {
          ...ruleSummary,
          details,
          level: details?.risk,
        };
      });

      setRuleSummaries(fullRuleSummaries);
      setBehavioralRuleSummaries(
        fullRuleSummaries.filter(
          ruleSummary =>
            (ruleSummary.rule?.tool === 'pulsar' ||
              ruleSummary.rule?.tool === 'pulsar-ios') &&
            ruleSummary.details?.category === 'behavioral',
        ),
      );
    } catch (err) {
      toastrHelper.showErrorToast(
        'Error loading rule summary data',
        'Error',
        mastV2,
      );
    }
  };

  const fetchThreatScore = async () => {
    try {
      const { data } = await axios.get(`/api/threat-score?uuid=${farmAppUuid}`);

      setThreatScore(data.threatScore);
    } catch (err) {
      toastrHelper.showErrorToast(
        'Error loading threat score',
        'Error',
        mastV2,
      );
    }
  };

  const fetchMetadata = async () => {
    try {
      const { data } = await axios.get(`/farm-apps/metadata/${farmAppUuid}`);

      setMetadata(data);
    } catch (err) {
      toastrHelper.showErrorToast('Error loading metadata', 'Error', mastV2);
    }
  };

  const fetchMaliciousResult = async () => {
    try {
      const malwareRule = ruleSummaries.find(ruleSummary =>
        ruleSummary.rule?.id?.startsWith('malware/'),
      );
      if (malwareRule) {
        const { data } = await axios.get(
          `${sarifBaseURL}/evidence?uuid=${farmAppUuid}&ruleGuid=${malwareRule.guid}`,
        );
        const result =
          data.entries && data.entries.length > 0 ? data.entries[0].result : {};
        setMaliciousResult(result);
      }
    } catch (err) {
      toastrHelper.showErrorToast(
        'Error loading rule summary data',
        'Error',
        mastV2,
      );
    }
  };

  const handleTabChange = (event, newTabValue) => {
    setActiveTab(newTabValue);
  };

  useEffect(() => {
    checkDataLoaded();
    if (!isDataLoaded) return;
    fetchAggregateData();
    getApp();
    fetchRuleSummaries();
    fetchThreatScore();
    fetchMetadata();
    dispatch(setPageTitle('New Report'));
  }, [isDataLoaded]);

  useEffect(() => {
    fetchMaliciousResult();
  }, [ruleSummaries]);

  if (!newQuokkaReport) {
    return (
      <QContainer>
        <QTypography variant="h6">New Quokka Report is disabled</QTypography>
      </QContainer>
    );
  }

  return (
    <QContainer
      fluid
      sx={{
        maxWidth: '100% !important',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '40px',
      }}
    >
      {app && (
        <GuardianReportHeaderAndMetadata
          app={app}
          threatScore={threatScore}
          metadata={metadata}
        />
      )}
      <QTabContext value={activeTab}>
        <QTabList
          onChange={handleTabChange}
          sx={{
            '.Mui-selected': {
              color: '#487F87 !important',
              fontWeight: '700',
            },
            '.MuiTab-root': {
              color: '#757575',
            },
          }}
        >
          <QTab label="Overview" value="overview" />
          <QTab label="Behaviors" value="behaviors" />
          <QTab label="Network Info" value="network_info" />
          <QTab label="SBOM" value="sbom" />
          <QTab label="Malicious Report" value="malicious_report" />
        </QTabList>
        <QTabPanel value="overview" sx={{ padding: 0 }}>
          <OverviewTab
            aggregateData={aggregateData}
            ruleSummaries={ruleSummaries}
            farmAppUuid={farmAppUuid}
            maliciousResult={maliciousResult}
          />
        </QTabPanel>
        <QTabPanel value="behaviors" sx={{ padding: 0 }}>
          <BehaviorsTab
            ruleSummaries={behavioralRuleSummaries}
            farmAppUuid={farmAppUuid}
          />
        </QTabPanel>
        <QTabPanel value="network_info">Network Info</QTabPanel>
        <QTabPanel value="sbom">SBOM</QTabPanel>
        <QTabPanel value="malicious_report">
          <MaliciousTab
            app={app}
            ruleSummaries={ruleSummaries}
            maliciousResult={maliciousResult}
            aggregateData={aggregateData}
          />
        </QTabPanel>
      </QTabContext>
    </QContainer>
  );
}
